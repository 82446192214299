import createApolloClient from 'core/createApolloClient';

import { DEALER_BY_ID } from 'queries/dealer-api';

import * as USER from './actionTypes';

import { actions as dealerActions } from '../dealer';
import { actions as iuaUserActions } from '../iua';

/**
 * Set active dealer for the user
 * @param {number} dealerId - id matching the selected dealer
 */
export const setDealer = dealerId => async dispatch => {
    await dispatch({ type: USER.SET_DEALER, dealerId });
};

/**
 * Set active dealer for the user
 * @param {number} dealerId - id matching the selected dealer
 */
export const changeDealer = dealerId => async (dispatch, getState) => {
    if (!dealerId) {
        return;
    }

    try {
        await dispatch(dealerActions.setDealerApiLoading({ loading: true }));

        const apolloClient = createApolloClient();

        const { data } = await apolloClient.query({
            variables: {
                id: parseInt(dealerId, 10)
            },
            query: DEALER_BY_ID
        });

        // Only do the IUA call if the user is an IUA user
        const reduxState = getState();
        if (reduxState && reduxState.iua && reduxState.iua.authenticated) {
            dispatch(iuaUserActions.changeIUAMainDealer(dealerId.toString()));
        }

        await dispatch(dealerActions.setDealerApiData({ data: data.dealerById }));
        await dispatch({ type: USER.SET_DEALER, dealerId });
    } catch (e) {
        console.error(e);
        await dispatch(dealerActions.setDealerApiError({ error: true }));
    }
};

/**
 * Set user object
 */
export const setUserObject = payload => ({
    type: USER.SET_USER_OBJECT,
    payload
});

export const unauthenticated = () => dispatch => {
    dispatch({ type: USER.SET_UNAUTHENTICATED });
};

/**
 * Set user as authenticated on initialization
 * @param {Object} userObject
 * @param {number} userObject.level
 * @param {string} dealerCode
 */
export const initAuth = userObject => async dispatch => {
    dispatch(setUserObject(userObject));
};

/**
 * Logout user
 */
export const deauthenticate = () => async dispatch => {
    dispatch(iuaUserActions.logout());
};
