import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar } from '@bytbil/bootstrap-react';
import StockNavigationItems from './Navigation/StockNavigationItems';
import OfferNavigationItems from './Navigation/OfferNavigationItems';
import StatisticsNavigationItems from './Navigation/StatisticsNavigationItems';
import DealerNavigationItems from './Navigation/DealerNavigationItems';
import CustomerServiceNavigationItems from './Navigation/CustomerServiceNavigationItems';
import AccountServiceNavigationItems from './Navigation/AccountNavigationItems';
import AccountServiceNavigationProductSubItems from './Navigation/AccountNavigationProductSubItems';
import DCBNavigationItems from './Navigation/DCBNavigationItems';

const SubHeader = React.memo(() => {
    const itemClassName = 'px-1 px-md-2';

    return (
        <Navbar className="nav-sub d-none d-xl-block p-0" id="navbar-nav" role="navigation" aria-expanded="false">
            <ul className="container d-flex flex-row navbar-nav fs-3">
                <Routes>
                    <Route path="/offer" element={<OfferNavigationItems className={itemClassName} />} />

                    <Route path="/offer/equipment" element={<OfferNavigationItems className={itemClassName} />} />
                    <Route path="/offer/:id" element={<OfferNavigationItems className={itemClassName} id />} />

                    <Route path="/statistics" element={<StatisticsNavigationItems className={itemClassName} />} />
                    <Route
                        path="/statistics/active-ads"
                        element={<StatisticsNavigationItems className={itemClassName} />}
                    />

                    <Route path="/vehicles" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/vehicles/history" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/edit-regno" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/bumpsettings" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/prices" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/move-stock" element={<StockNavigationItems className={itemClassName} />} />
                    <Route path="/import-status" element={<StockNavigationItems className={itemClassName} />} />

                    <Route path="/dealer/edit" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route path="/dealer/presentation" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route path="/dealer/finance" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route path="/dealer/pricing" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route path="/dealer/sellers" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route path="/dealer" element={<DealerNavigationItems className={itemClassName} />} />
                    <Route
                        path="/dealer/invoicehistory"
                        element={<DealerNavigationItems className={itemClassName} />}
                    />

                    <Route
                        path="/customerservice"
                        element={<CustomerServiceNavigationItems className={itemClassName} />}
                    />

                    <Route path="/account" element={<AccountServiceNavigationItems className={itemClassName} />} />

                    <Route path="/products" element={<AccountServiceNavigationProductSubItems />} />

                    <Route path="/dcb/orders" element={<DCBNavigationItems className={itemClassName} />} />
                    <Route path="/dcb/history" element={<DCBNavigationItems className={itemClassName} />} />
                </Routes>
            </ul>
        </Navbar>
    );
});

export default SubHeader;
