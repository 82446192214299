import { createSelector } from 'reselect';

import { RootState } from 'state/types';

export const getIsAuthenticated = (state: RootState) => state.iua.authenticated;
export const getUser = (state: RootState) => state.iua;
export const getAllDealerCodes = (state: RootState) => state.iua.dealersData.map(({ code }) => code).join();

export const getDealers = state => state.dealer.dealerApi.groupMembers;
export const getDealerProducts = state => state.dealer.dealerApi.products;

export const getDealerById = id =>
    createSelector([getDealers], dealerItems => dealerItems.find(item => item.id === id));
